@import '../../../styles/variables';

.SVGEditor {
  display: block;

  .editor-window {
    background-color: $componentColorGrey;
  }

  .editor-toolbox {
    background-color: $componentColorGrey;
  }
}
