@import '../../../styles/variables';

.MyNavbar {
  display: block;
  border-bottom: solid 0.05rem lightgray;

  .nav-brand {
    font-family: $brandFontFamily; 
  }

  .nav-center {
    padding-right: 7rem;
  }

  .auth-button {
    width: 86.06px;
    cursor: pointer;
  }
}
