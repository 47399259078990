@import '../../../styles/variables';

.Documents {
  display: block;

  .documents-body {
    background-color: $componentColorGrey;
    height: 500px;

    .nav {
      border: none;
      margin-left: 0.5%;
      .nav-item {
        border: none;
      }
    }

    .documents-navbar {
      flex: 0 0 20%;
      height: 400px;
    }

    .documents-textarea {
      flex: 0 0 79.6%;
      height: 400px;
    }
  }
}
