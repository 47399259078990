@import '../../../styles/variables';

.Home {
  display: block;

  .SignUpCol {
    background-color: $componentColorGrey;
    padding-top: 3.0rem;

    .SignUpInfo {
      font-size: 0.9rem;
      margin: 0px;
    }
  }
}
